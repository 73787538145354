<template>
  <div class="px-9 mt-5">
    <v-list-item-group class="mb-5">
      <v-list-item-content class="py-4 pl-0">
        <s-text weight="normal" size="sm" color="grayTextAlt" class="mb-2"
          >Name</s-text
        >
        <s-text weight="bold" size="lg" color="black">
          {{ endpoint.name }}
        </s-text>
      </v-list-item-content>
      <v-list-item-content :ripple="false" class="top-thin-border">
        <v-col class="pl-0">
          <s-text weight="normal" size="sm" color="grayTextAlt" class="mb-4">
            Description
          </s-text>
          <s-text size="lg" weight="bold">
            {{ endpoint.description || 'n/a' }}
          </s-text>
        </v-col>
      </v-list-item-content>
      <v-list-item-content :ripple="false" class="top-thin-border">
        <v-col class="pl-0">
          <s-text weight="normal" size="sm" color="grayTextAlt" class="mb-4">
            FQDN
          </s-text>
          <s-text size="lg" weight="bold">
            <span class="count-box bg-white pa-1 mr-2">
              {{ endpoint.type }}
            </span>
            {{ fqdn }}
          </s-text>
        </v-col>
      </v-list-item-content>
    </v-list-item-group>
    <s-btn @click="goBack" type="button" color="primary" elevation="0">
      OK
    </s-btn>
  </div>
</template>

<script>
export default {
  name: 'ViewEnpoint',
  props: {
    endpoint: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fqdn() {
      return this.endpoint.origin
        ? `${this.endpoint.origin.fqdn}:${this.endpoint.origin.port}`
        : this.endpoint.fqdn
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
  },
}
</script>
<style lang="scss" scoped>
.count-box {
  background: #dfe2e6;
  border: 1px solid #dfe2e6;
  border-radius: 6px;
  color: var(--v-gray-base);
  font-size: 14px;
}
</style>
