<template>
  <div>
    <v-row>
      <v-col>
        <div style="max-width: 500px">
          <v-list-item-group>
            <v-list-item-content class="py-4 pl-0">
              <s-text weight="normal" size="sm" color="grayTextAlt" class="mb-2"
                >Description</s-text
              >
              <s-text weight="bold" size="lg" color="black" class="mb-4">
                {{ app.description }}
              </s-text>
            </v-list-item-content>
            <v-list-item-content class="top-thin-border py-4 pl-0 mb-4">
              <s-text
                weight="normal"
                size="sm"
                color="grayTextAlt"
                class="mb-2"
              >
                Endpoints
              </s-text>
              <view-endpoints
                :endpoints="app.forwarders"
                @showAppEndpoint="addEndpoint"
                @view="viewEndpoint"
              />
            </v-list-item-content>
            <v-list-item-content
              :ripple="false"
              class="top-thin-border pl-0 pt-3"
            >
              <v-col class="pl-0">
                <s-text
                  weight="normal"
                  size="sm"
                  color="grayTextAlt"
                  class="mb-4"
                >
                  Date Created
                </s-text>
                <s-text size="lg" weight="bold">
                  {{
                    app.created_at ? getHumanReadableDate(app.created_at) : '-'
                  }}
                </s-text>
              </v-col>
            </v-list-item-content>
          </v-list-item-group>
          <div class="d-flex align-center pt-8">
            <s-btn type="button" color="primary" elevation="0" @click="editApp">
              <v-icon class="mr-2">mdi-pencil-outline</v-icon>
              Edit App
            </s-btn>
            <s-btn
              weight="medium"
              size="md-m"
              class="delete-btn ml-4"
              elevation="0"
              @click.stop="dialog = true"
            >
              <v-icon class="mr-2" color="primary"
                >mdi-trash-can-outline</v-icon
              >
              Delete
            </s-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="290">
      <template> </template>
      <v-card>
        <v-card-title class="text-h6">
          Are you sure you want to delete {{ app.name }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteApp()"> Ok </v-btn>
          <v-btn color="primary" text @click="dialog = false"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <s-dialog v-model="showModal" persistent isModal :title="'Add Endpoint'">
      <app-endpoint-form v-if="showModal" type="app" />
    </s-dialog>
    <s-dialog
      v-model="showViewModal"
      persistent
      isModal
      :title="'View Endpoint'"
    >
      <view-endpoint v-if="showViewModal" :endpoint="endpoint" />
    </s-dialog>
  </div>
</template>
<script>
import ViewEndpoints from '@/components/apps/ViewEndpoints'
import ViewEndpoint from '@/components/apps/ViewEndpoint'
import AppEndpointForm from '@/components/apps/AppEndpointForm'
import { mapGetters } from 'vuex'
import { getHumanReadableDate } from '@/utils/date'
export default {
  name: 'AppDetails',
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  components: {
    'view-endpoints': ViewEndpoints,
    'view-endpoint': ViewEndpoint,
    'app-endpoint-form': AppEndpointForm,
  },
  data() {
    return {
      isDeleting: false,
      showDeleteDialog: false,
      dialog: false,
      endpoint: {},
      showModal: this.$route.meta.showModal,
      showViewModal: this.$route.meta.showViewModal,
    }
  },
  computed: {
    ...mapGetters({
      app: 'edge/currentApp',
    }),
  },
  methods: {
    getHumanReadableDate,
    addEndpoint() {
      this.$router.push({
        name: 'addMoreEdgeEndpoint',
        params: { id: this.id },
      })
    },
    viewEndpoint(endpoint) {
      this.endpoint = endpoint
      this.$router.push({ name: 'viewEdgeEndpoint', params: { id: this.id } })
    },
    editApp() {
      this.$router.push({ name: 'editEdge', id: this.id })
    },
    goBack() {
      this.$router.replace({ name: 'edges' })
    },
    async deleteApp() {
      this.isDeleting = true
      this.dialog = false
      this.$store
        .dispatch('edge/deleteApp', this.id)
        .then(() => {
          this.goBack()
        })
        .finally(() => {
          this.isDeleting = false
        })
    },
  },
  watch: {
    '$route.meta'({ showModal, showViewModal }) {
      this.showModal = showModal
      this.showViewModal = showViewModal
    },
  },
}
</script>
<style lang="scss" scoped>
.img {
  height: 300px;
}
.bg-white {
  background: white;
}

.delete-btn {
  color: #ec3944;
  background-color: #fff !important;
}
</style>
